var base = require("base/login/login");
var formValidation = require("@sfra/js/components/formValidation");
var loginHelper = require("./loginHelper");
var CryptoJS = require("crypto-js");
var dyHelper = require("../dynamicyield/dy");
require("@sfra/js/components/spinner");

function wishlistUpdateIds() {
    var url = $(".wishlist-info-wrap").attr("data-url");

    $.ajax({
        url: url,
        type: "get",
        dataType: "html",
        success: function (data) {
            $(".wishlist-info-wrap").html(data);
            $("body").trigger("wishlist:update_icon");

        },
    });
}

base.login = function () {
    $("form.login").submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr("action");
        form.spinner().start();
        var isReload = $(this).closest("div[data-reload]").data("reload");
        $("form.login").trigger("login:submit", e);
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                    $("form.login").trigger("login:error", data);
                    if (data.redirectUrl) {
                        location.href = data.redirectUrl;
                    }
                } else {
                    $("form.login").trigger("login:success", data);
                    
                    if ($("#loginModal").modal("show").length) {
                        $("#loginModal").modal("hide");
                        $(document).trigger("load-header");
                        $("#js-loginOverlay").removeClass("form-input-overlay");
                        $(".js-isAuthenticated").removeClass("non-registerCase");
                        wishlistUpdateIds();
                    } else {
                        location.href = data.redirectUrl;
                    }
                    
                    var dyForm = {
                        hashedemail: CryptoJS.SHA256(data.hashedemail).toString(CryptoJS.enc.Hex),
                        customerNo: data.customerNo
                    };

                    var syncForm = {
                        syncCart: data.syncCart
                    };
                    dyHelper.fireDYEvent("LOGIN", dyForm);
                    dyHelper.fireDYEvent("SYNC_CART", syncForm);
                    if (isReload) window.location.reload();

                }
                //if (isReload) window.location.reload();
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    $("form.login").trigger("login:error", data);
                    form.spinner().stop();
                }
            }
        });
        return false;
    });

    $(document).on("load-header", function () {
        if ($(".account-header-url").length) {
            var url = $(".account-header-url").data("url");
            $.ajax({
                url: url,
                type: "get",
                success: function (data) {
                    $(".sign-in-menu-container").html("");
                    $(".sign-in-menu-container").html(data);
                    if ($(".add-wishlist-process").find(".custom-add-wishlist-btn").length) {
                        $(".add-wishlist-process").find(".custom-add-wishlist-btn").trigger("click");
                    }
                }
            });
        }
    });
    if ($("form.redirectLogin").length) {
        $("form.redirectLogin").submit();
    }
};

base.register = function () {
    $("#register form.registration").submit(function (e) {
        loginHelper.registerCustomerAjax(e);
    });
};


module.exports = base;
